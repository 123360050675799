import * as React from "react"
import styled from "styled-components"
import { FC, useContext, useEffect, useState } from "react"
import OrderType from "../../model/OrderType"
import LinkButton from "../../components/button/LinkButton"
import ShareableSelectionPageConfig from "./ShareableSelectionPageConfig"
import * as orderApi from "../../api/platform/orderApi"
import { LoginContext, Spinner, Tab, Tabs } from "@social-supermarket/social-supermarket-components"
import PendingApprovalRecipientList from "./PendingApprovalRecipientList"
import AllRecipientList from "./AllRecipientList"
import InviteByEmail from "./InviteByEmail"
import GiftNoteConfig from "./GiftNoteConfig"
import PaymentList from "./PaymentList"
import OrderItems from "./OrderItems"
import GiftVoucherConfig from "./GiftVoucherConfig"
import { PrimaryButton } from "@social-supermarket/social-supermarket-components"
import { colors, RecipientType } from "@social-supermarket/social-supermarket-model"

const Container = styled.div`
  height: calc(100% - 5px);
  width: 100%;
  position: relative;
`
const TabContainer = styled.div`
  height: calc(100% - 35px);
  width: 100%;
  padding: 5px;
`
const EditLink = styled(LinkButton)`
  font-size: 14px;
`
const StyledTabs = styled(Tabs)`
  background-color: white;
  margin-left: 5px;
  padding: 15px;
  margin-bottom: 5px;
  margin-right: 5px;
`
const SaveTitleButton = styled(PrimaryButton)`
  &&& {
    width: 200px;
    padding: 11px 40px;
  }
`
const TitleForm = styled.form`
  display: flex;
  align-items: flex-start;
  margin: 7px 0 0;

  input {
    width: 400px;
    margin-right: 10px;
  }
`
const Title = styled.div`
  padding: 15px 15px 5px 20px;
  margin-left: 5px;
  align-items: flex-start;
  background-color: white;
  margin-right: 5px;
  margin-top: 5px;
  display: flex;

  h1 {
    margin: 15px 0 0;

    i {
      font-size: 12px;
    }
  }
`
const LeftTitle = styled.div`
  flex: 1;
`

const StyledButton = styled(PrimaryButton)`
  width: 100px;
  padding: 5px;
  margin-right: 10px;
  font-size: 0.8em;
`

const StyledInput = styled.input<{ isValid: boolean }>`
  ${({ isValid }) =>
    !isValid &&
    `
        &,
        &:focus {
          box-shadow: 0 0 3px ${colors.rouge} !important;
          border: 1px solid ${colors.rouge} !important;
        }
        ::placeholder {
          color: ${colors.rouge};
        }
      `};
`

const Error = styled.div`
  color: ${colors.rouge};
`

interface Props {
  orderKey: string
  onBack?: () => void
}

const OrderPage: FC<Props> = ({ orderKey, onBack }) => {
  const { token, initialising, user } = useContext(LoginContext)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [title, setTitle] = useState<string>("")
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false)
  const [order, setOrder] = useState<OrderType>(null)
  const [responses, setResponses] = useState<RecipientType[]>([])
  const [currentTab, setCurrentTab] = useState<number>(0)

  useEffect(() => {
    if (orderKey && !initialising) {
      fetchOrder()
    }
  }, [orderKey, initialising])

  useEffect(() => {
    setTitle(order?.name || "")
  }, [order])

  useEffect(() => {
    if (order) {
      const tabs = renderTabs()
      if (currentTab >= tabs.length) {
        setCurrentTab(tabs.length - 1)
      }
    }
  }, [responses])

  const fetchOrder = async () => {
    try {
      setIsLoading(true)
      const { order } = await orderApi.getOrder(token, orderKey)
      setOrder(order)
      setResponses(order?.recipients || [])
    } catch (e) {
      console.log(`Unexpected error, please contact support: ${e}`)
    } finally {
      setIsLoading(false)
    }
  }

  const handleEmailInvitesComplete = () => {
    setIsLoading(true)
    setTimeout(async () => {
      await fetchOrder()
      setCurrentTab(2)
    }, 1000)
  }

  const handlePaymentComplete = () => {
    waitAndFetch()
  }

  const waitAndFetch = () => {
    setIsLoading(true)
    setTimeout(() => {
      fetchOrder()
    }, 1000)
  }

  const handleEditTitle = () => {
    setIsEditingTitle(true)
  }

  const handleSaveTitle = async event => {
    event.preventDefault()
    if (title.trim()) {
      try {
        setIsLoading(true)
        const newOrder = await orderApi.updateOrderName(token, order.key, title)
        setOrder(newOrder)
        setIsEditingTitle(false)
      } catch (e) {
        console.log(`Unexpected error, please contact support: ${e}`)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const pendingResponse = responses.filter(response => response.status === "pending_selection")
  const pendingApproval = responses.filter(response => response.status === "pending_approval")

  const renderTabs = () => {
    const tabs = [
      <Tab title={"Invite"} id="invite">
        <ShareableSelectionPageConfig order={order} setOrder={order => setOrder(order)} />
        <InviteByEmail order={order} onComplete={handleEmailInvitesComplete} />
        <GiftNoteConfig order={order} setOrder={order => setOrder(order)} />
        <GiftVoucherConfig order={order} updateList={order => setOrder(order)} />
      </Tab>,
      <Tab title={"Products"} id="products">
        <OrderItems order={order} />
      </Tab>,
    ]
    if (responses.length) {
      tabs.push(
        <Tab title={`All Recipients (${responses.length})`} id="all-recipients">
          <AllRecipientList order={order} responses={responses} refresh={fetchOrder} />
        </Tab>
      )
    }
    // if (pendingResponse.length) {
    //   tabs.push(
    //     <Tab title={`Pending Selection (${pendingResponse.length})`}>
    //       <AllResponseList responses={pendingResponse} refresh={fetchOrder} />
    //     </Tab>
    //   )
    // }
    if (pendingApproval.length) {
      tabs.push(
        <Tab title={`Pending Approval (${pendingApproval.length})`} id="pending-approval">
          <PendingApprovalRecipientList
            recipients={pendingApproval}
            waitAndFetch={waitAndFetch}
            order={order}
            refresh={fetchOrder}
          />
        </Tab>
      )
    }
    if (order.payments && order.payments.length > 0) {
      tabs.push(
        <Tab title={`Payments (${order.payments.length})`} id="payments">
          <PaymentList payments={order.payments} />
        </Tab>
      )
    }
    return tabs
  }
  return (
    <Container>
      <Spinner isLoading={isLoading} />
      {order && (
        <TabContainer>
          <Title>
            <LeftTitle>
              {onBack && <LinkButton onClick={onBack}>{"< Back to Orders"}</LinkButton>}
              {!isEditingTitle && (
                <h1>
                  {order.name} <EditLink onClick={handleEditTitle}>Edit</EditLink>
                </h1>
              )}
              {isEditingTitle && (
                <TitleForm onSubmit={handleSaveTitle}>
                  <div>
                    <StyledInput
                      type="text"
                      value={title}
                      onChange={event => setTitle(event.target.value)}
                      isValid={title.trim()}
                    />
                    {!title.trim() && <Error>Please enter a valid order name</Error>}
                  </div>
                  <SaveTitleButton>Save</SaveTitleButton>
                </TitleForm>
              )}
            </LeftTitle>
            <StyledButton onClick={fetchOrder}>Refresh</StyledButton>
          </Title>
          <StyledTabs currentTab={currentTab} setCurrentTab={i => setCurrentTab(i)}>
            {renderTabs()}
          </StyledTabs>
        </TabContainer>
      )}
    </Container>
  )
}

export default OrderPage
