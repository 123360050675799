import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import OrderType from "../../model/OrderType"
import { AgGridColumn, AgGridReact } from "ag-grid-react"
import { GridReadyEvent, RowClickedEvent } from "ag-grid-community/dist/lib/events"
import { GridApi } from "ag-grid-community"

const Container = styled.div`
  margin: 5px;
  height: calc(100% - 10px);
  width: calc(100% - 10px);

  .ag-row {
    cursor: pointer;
  }
`

interface Props {
  orders: OrderType[]
  onSelect: (order: OrderType) => void
}

const OrderList: FC<Props> = ({ orders, onSelect }) => {
  let gridApi: GridApi = null

  const sortedOrders = [...orders]
  sortedOrders.sort((a, b) => {
    if (a.date > b.date) {
      return -1
    }
    if (b.date > a.date) {
      return 1
    }
    return 0
  })

  const handleGridReady = (gridReady: GridReadyEvent) => {
    gridApi = gridReady.api
    gridReady.api.sizeColumnsToFit()
  }

  const handleRowClicked = (event: RowClickedEvent) => {
    onSelect(event.data)
  }

  return (
    <Container className="ag-theme-alpine">
      <AgGridReact
        rowData={sortedOrders}
        onGridReady={handleGridReady}
        rowSelection={"single"}
        onRowClicked={handleRowClicked}
        suppressCellSelection={true}
        domLayout={"autoHeight"}
      >
        <AgGridColumn field="ID" headerName="Order #" valueFormatter={e => `P-${e.value}`} />
        <AgGridColumn field="name" />
        <AgGridColumn field="date" headerName="Created" />
      </AgGridReact>
    </Container>
  )
}

export default OrderList
