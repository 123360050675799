import * as React from "react"
import { FC, useContext, useState } from "react"
import styled from "styled-components"
import useQueryString from "../../hooks/useQueryString"
import { LoginContext, Spinner } from "@social-supermarket/social-supermarket-components"
import OrderType from "../../model/OrderType"
import OrderPage from "./OrderPage"
import OrderList from "./OrderList"
import Placeholder from "../../components/Placeholder"
import { GiftListContext } from "../../context/GiftListProvider"
import { navigate } from "gatsby"

const Container = styled.div`
  padding: 5px;
  height: 100%;
  position: relative;
`

interface Props {}

const OrdersPage: FC<Props> = () => {
  const { token } = useContext(LoginContext)
  const [key, setKey] = useQueryString("key", "")
  const [loading, setLoading] = useState<boolean>(false)
  const { allGiftLists } = useContext(GiftListContext)

  const handleBack = () => {
    // fetchOrders()
    // setKey("")
    navigate("/gifting?tab=orders")
  }

  const handleSelect = (order: OrderType) => {
    setKey(order.key)
  }

  return (
    <Container>
      <Spinner isLoading={loading} />
      {key && <OrderPage orderKey={key} onBack={handleBack} />}
      {!key && allGiftLists && <OrderList orders={allGiftLists} onSelect={handleSelect} />}
      {!key && !allGiftLists && (
        <Placeholder
          label={'Click "Send a gift" to start placing orders which will then appear here.'}
        />
      )}
    </Container>
  )
}

export default OrdersPage
